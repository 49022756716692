
import { defineComponent } from 'vue'
import { Orders } from '@/store'
import { OrderType, OrderIcon } from '@/models/orders.models'
import { numberToRUB } from '@/scripts/filters'

export default defineComponent({
	name: 'OrdersStats',

	computed: {
		orderIcon() {
			return OrderIcon
		},
		stats() {
			const newOrder = { sum: 0, profit: 0, left: 0 }
			const orderFromFinished = { sum: 0, profit: 0, left: 0 }
			const collection = { sum: 0, profit: 0, left: 0 }
			const all = { sum: 0, profit: 0, left: 0 }

			Orders.orders.forEach((order) => {
				const selectedJewelry = Orders.jewelryWithCalc.filter((jewelry) => jewelry.id === order.jewelryId)[0] || 0

				const price = order.price ? +order.price : selectedJewelry.price ? +selectedJewelry.price : 0
				const costPrice = order.costPrice
					? +order.costPrice
					: selectedJewelry.price && selectedJewelry.extraPercent
					? Math.round((+selectedJewelry.price / (100 + +selectedJewelry.extraPercent)) * 100)
					: 0
				const prePay = order.prepay ? +order.prepay : 0

				switch (order.orderType) {
					case OrderType.NewOrder:
						newOrder.sum += price
						newOrder.profit += price - costPrice
						if (!order.completed) newOrder.left += price - prePay
						break

					case OrderType.OrderFromFinished:
						orderFromFinished.sum += price
						orderFromFinished.profit += price - costPrice
						if (!order.completed) orderFromFinished.left += price - prePay
						break

					case OrderType.Collection:
						collection.sum += price
						collection.profit += price - costPrice
						if (!order.completed) collection.left += price - prePay
						break
				}

				// console.log(order.price)
			})

			all.sum = newOrder.sum + orderFromFinished.sum + collection.sum
			all.profit = newOrder.profit + orderFromFinished.profit + collection.profit
			all.left = newOrder.left + orderFromFinished.left + collection.left

			return { newOrder, orderFromFinished, collection, all }
		},
	},
	methods: {
		numberToRUB(value: number) {
			return numberToRUB(value)
		},
	},
})
