<template>
	<div class="row p-3">
		<div class="d-grid col text-center p-0">
			<div class="fw-bold text-secondary"><i :class="orderIcon.NewOrder"></i><br />Новые</div>
			<div>{{ numberToRUB(stats.newOrder.sum) }}</div>
			<div class="fw-bold">{{ numberToRUB(stats.newOrder.profit) }}</div>
			<div class="text-success small">+{{ numberToRUB(stats.newOrder.left) }}</div>
		</div>
		<div class="d-grid col text-center p-0">
			<div class="fw-bold text-secondary"><i :class="orderIcon.OrderFromFinished"></i><br />Из готовых</div>
			<div>{{ numberToRUB(stats.orderFromFinished.sum) }}</div>
			<div class="fw-bold">{{ numberToRUB(stats.orderFromFinished.profit) }}</div>
			<div class="text-success small">+{{ numberToRUB(stats.orderFromFinished.left) }}</div>
		</div>
		<div class="d-grid col text-center p-0">
			<div class="fw-bold text-secondary"><i :class="orderIcon.Collection"></i><br />Коллекции</div>
			<div>{{ numberToRUB(stats.collection.sum) }}</div>
			<div class="fw-bold">{{ numberToRUB(stats.collection.profit) }}</div>
			<div class="text-success small">+{{ numberToRUB(stats.collection.left) }}</div>
		</div>
		<div class="d-grid col text-center p-0">
			<div class="fw-bold text-secondary"><i :class="orderIcon.All"></i><br />Всего</div>
			<div>{{ numberToRUB(stats.all.sum) }}</div>
			<div class="fw-bold">{{ numberToRUB(stats.all.profit) }}</div>
			<div class="text-success small">+{{ numberToRUB(stats.all.left) }}</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Orders } from '@/store'
import { OrderType, OrderIcon } from '@/models/orders.models'
import { numberToRUB } from '@/scripts/filters'

export default defineComponent({
	name: 'OrdersStats',

	computed: {
		orderIcon() {
			return OrderIcon
		},
		stats() {
			const newOrder = { sum: 0, profit: 0, left: 0 }
			const orderFromFinished = { sum: 0, profit: 0, left: 0 }
			const collection = { sum: 0, profit: 0, left: 0 }
			const all = { sum: 0, profit: 0, left: 0 }

			Orders.orders.forEach((order) => {
				const selectedJewelry = Orders.jewelryWithCalc.filter((jewelry) => jewelry.id === order.jewelryId)[0] || 0

				const price = order.price ? +order.price : selectedJewelry.price ? +selectedJewelry.price : 0
				const costPrice = order.costPrice
					? +order.costPrice
					: selectedJewelry.price && selectedJewelry.extraPercent
					? Math.round((+selectedJewelry.price / (100 + +selectedJewelry.extraPercent)) * 100)
					: 0
				const prePay = order.prepay ? +order.prepay : 0

				switch (order.orderType) {
					case OrderType.NewOrder:
						newOrder.sum += price
						newOrder.profit += price - costPrice
						if (!order.completed) newOrder.left += price - prePay
						break

					case OrderType.OrderFromFinished:
						orderFromFinished.sum += price
						orderFromFinished.profit += price - costPrice
						if (!order.completed) orderFromFinished.left += price - prePay
						break

					case OrderType.Collection:
						collection.sum += price
						collection.profit += price - costPrice
						if (!order.completed) collection.left += price - prePay
						break
				}

				// console.log(order.price)
			})

			all.sum = newOrder.sum + orderFromFinished.sum + collection.sum
			all.profit = newOrder.profit + orderFromFinished.profit + collection.profit
			all.left = newOrder.left + orderFromFinished.left + collection.left

			return { newOrder, orderFromFinished, collection, all }
		},
	},
	methods: {
		numberToRUB(value: number) {
			return numberToRUB(value)
		},
	},
})
</script>
