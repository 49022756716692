<template>
	<div
		class="bg-white rounded-3 shadow border p-2"
		:class="{
			'border-danger': sourceOrder.endDate.getTime() < Date.now() && sourceOrder.completed !== true,
			hover: sourceOrder.id === hoveredOrderId,
			completed: sourceOrder.completed === true && OrdersFilter.completeness === OrdersCompleteness.All && sourceOrder.id !== hoveredOrderId,
		}"
	>
		<div class="row align-items-center">
			<div class="col-auto">
				<div v-show="selectedJewelry.thumbnail" class="square-ratio" style="width: 80px;">
					<router-link
						:to="{
							name: 'SingleJewelry',
							params: { id: selectedJewelry.slug },
						}"
					>
						<img :src="selectedJewelry.thumbnail" />
					</router-link>
				</div>
			</div>
			<div class="col ps-0 d-grid gap-1">
				<div class="position-relative">
					<i :class="orderIcon" class="text-muted position-absolute top-50 translate-middle-y ms-1"></i>
					<input v-model="title" :placeholder="placeholderTitle" type="text" class="form-control w-100 border-0 ps-4 pe-1 py-0 fw-bold" />
				</div>
				<div class="position-relative">
					<i class="fas fa-user text-muted position-absolute top-50 translate-middle-y ms-1"></i>
					<input v-model="client" placeholder="Имя клиента" type="text" class="form-control w-100 border-0 ps-4 pe-1 py-0" />
				</div>
				<div class="row align-items-center g-1">
					<div class="col-auto">
						<datepicker v-model="startDate" inputFormat="dd-MM-yy" :locale="ruLocale" class="datepicker" size="8" />
					</div>
					<div class="col-auto text-muted">
						→
					</div>
					<div class="col-auto">
						<datepicker v-model="endDate" inputFormat="dd-MM-yy" :lower-limit="startDate" :locale="ruLocale" class="datepicker" size="8" />
					</div>
					<div class="col ps-2">{{ numberToRUB(price ? price : placeholderPrice) }}</div>
					<div class="col ps-2 small text-success">+{{ numberToRUB((price ? price : placeholderPrice) - prepay) }}</div>
					<div class="col text-end">
						<a class="me-2 link-secondary" data-bs-toggle="collapse" :href="'#collapse-' + sourceOrder.id"><i class="fas fa-bars"></i></a>
					</div>
				</div>
			</div>
		</div>
		<div class="collapse" :id="'collapse-' + sourceOrder.id">
			<hr />
			<div class="row">
				<div class="col-4 position-relative">
					<i :class="orderIcon" class="text-muted position-absolute top-50 translate-middle-y ms-1"></i>
					<select v-model="orderType" class="form-select form-select-sm w-100 border-0 ps-4 pe-1 py-0">
						<option :value="OrderType.NewOrder">Новый</option>
						<option :value="OrderType.OrderFromFinished">Из готовых</option>
						<option :value="OrderType.Collection">Коллекция</option>
					</select>
				</div>
				<div class="col position-relative">
					<i class="fas fa-calculator text-muted position-absolute top-50 translate-middle-y ms-1"></i>
					<select v-model="jewelryId" class="form-select form-select-sm w-100 border-0 ps-4 pe-1 py-0">
						<option value="0">Без сметы</option>
						<option v-for="jewelry in jewelryWithCalc" :value="jewelry.id" :key="jewelry.id"> {{ jewelry.title }} ({{ jewelry.slug }}) </option>
					</select>
				</div>
			</div>
			<hr />
			<div class="row g-2">
				<div class="col">
					<div class="input-group input-group-sm">
						<input v-model="costPrice" :placeholder="placeholderCostPrice" type="number" class="form-control" />
						<span class="input-group-text">₽</span>
					</div>
					<div class="form-text">Себестоимость</div>
				</div>
				<div class="col">
					<div class="input-group input-group-sm">
						<input v-model="prepay" placeholder="0" type="number" class="form-control" />
						<span class="input-group-text">₽</span>
					</div>
					<div class="form-text">Предоплата</div>
				</div>
				<div class="col">
					<div class="input-group input-group-sm">
						<input v-model="price" :placeholder="placeholderPrice" type="number" class="form-control" />
						<span class="input-group-text">₽</span>
					</div>
					<div class="form-text">Стоимость</div>
				</div>
			</div>

			<hr />

			<div class="row align-items-center g-2">
				<div class="col">
					<div class="form-check form-switch mb-0 ms-1">
						<input v-model="completed" class="form-check-input" type="checkbox" />
						<label class="form-check-label">Готово</label>
					</div>
				</div>
				<div class="col text-end">
					<a @click.prevent="deleteOrder" class="link-secondary me-2" href><i class="fas fa-trash-alt"></i></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import _ from 'lodash'
import { defineComponent } from 'vue'
import { Order, OrdersCompleteness, OrderType, OrderIcon, JewelryWithCalc } from '@/models/orders.models'
import Datepicker from 'vue3-datepicker'
import { ru } from 'date-fns/locale'
import { Orders, OrdersFilter } from '@/store'
import { numberToRUB } from '@/scripts/filters'

export default defineComponent({
	name: 'OrderItem',
	components: {
		Datepicker,
	},
	props: { sourceOrder: { type: Object as () => Order, required: true } },
	data() {
		return {
			order: this.sourceOrder,
			ruLocale: ru,
		}
	},
	computed: {
		OrderType() {
			return OrderType
		},
		OrdersCompleteness() {
			return OrdersCompleteness
		},
		OrdersFilter() {
			return OrdersFilter
		},
		//Order prop GET SET
		title: {
			get(): string {
				return this.sourceOrder.title
			},
			set(title: string) {
				this.order.title = title
			},
		},
		orderType: {
			get(): string {
				return this.sourceOrder.orderType
			},
			set(orderType: string) {
				this.order.orderType = orderType
			},
		},
		client: {
			get(): string {
				return this.sourceOrder.client
			},
			set(client: string) {
				this.order.client = client
			},
		},
		jewelryId: {
			get(): number {
				return this.sourceOrder.jewelryId
			},
			set(jewelryId: number) {
				this.order.jewelryId = jewelryId
			},
		},
		costPrice: {
			get(): number | null {
				return this.sourceOrder.costPrice
			},
			set(costPrice: number) {
				this.order.costPrice = costPrice
			},
		},
		price: {
			get(): number | null {
				return this.sourceOrder.price
			},
			set(price: number) {
				this.order.price = price
			},
		},
		prepay: {
			get(): number | null {
				return this.sourceOrder.prepay
			},
			set(prepay: number) {
				this.order.prepay = prepay
			},
		},
		startDate: {
			get(): Date {
				return this.sourceOrder.startDate
			},
			set(startDate: Date) {
				this.order.startDate = startDate
			},
		},
		endDate: {
			get(): Date {
				return this.sourceOrder.endDate
			},
			set(endDate: Date) {
				this.order.endDate = endDate
			},
		},
		completed: {
			get(): boolean {
				return this.sourceOrder.completed
			},
			set(completed: boolean) {
				this.order.completed = completed
			},
		},
		//
		jewelryWithCalc() {
			return Orders.jewelryWithCalc
		},
		ordersCostprice() {
			return Orders.ordersCostprice
		},
		selectedJewelry(): JewelryWithCalc {
			return this.jewelryWithCalc.filter((jewelry) => jewelry.id === this.order.jewelryId)[0] || ({} as JewelryWithCalc)
		},
		placeholderTitle(): string {
			return this.selectedJewelry.title || 'Название заказа'
		},
		placeholderCostPrice(): number {
			return this.ordersCostprice[this.sourceOrder.jewelryId] ? this.ordersCostprice[this.sourceOrder.jewelryId] : 0
		},
		placeholderPrice(): number {
			return this.price ? this.price : this.selectedJewelry.price ? this.selectedJewelry.price : 0
		},
		hoveredOrderId() {
			return Orders.hoveredOrderId
		},
		orderIcon() {
			if (this.order.orderType === OrderType.NewOrder) return OrderIcon.NewOrder
			if (this.order.orderType === OrderType.OrderFromFinished) return OrderIcon.OrderFromFinished
			if (this.order.orderType === OrderType.Collection) return OrderIcon.Collection
			return ''
		},

		// orderClass() {},
	},

	methods: {
		numberToRUB(value: number) {
			return numberToRUB(value)
		},
		deleteOrder() {
			Orders.deleteOrder(this.order.id)
		},
	},

	watch: {
		order: {
			deep: true,
			handler: _.debounce((order: Order) => {
				Orders.saveOrder(order)
			}, 700),
		},
	},
})
</script>

<style lang="scss" scoped>
hr {
	margin: 0.7rem 0;
}
:deep(.datepicker) {
	border: 0 !important;
	padding: 1 !important;
	font-size: 0.8rem;
	color: gray;
	text-align: center;

	&:focus {
		outline-color: var(--bs-primary);
	}
}

.hover {
	box-shadow: 0 0.5rem 1rem rgb(13 110 253 / 35%) !important;
	border-color: var(--bs-primary) !important;
}

.completed {
	opacity: 0.3;
	&:hover {
		opacity: 1;
	}
}
</style>
