<template>
	<div class="d-grid gap-2 p-2">
		<Order v-for="order in orders" :key="order.id" :sourceOrder="order" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Orders, OrdersFilter } from '@/store'
import Order from '@/components/orders/orders-item.component.vue'
import { OrdersSort, OrdersCompleteness } from '@/models/orders.models'

export default defineComponent({
	name: 'OrdersList',
	components: {
		Order,
	},

	computed: {
		orders() {
			const orders = Orders.orders.filter((order) => {
				switch (OrdersFilter.completeness) {
					case OrdersCompleteness.Incomplete:
						return order.completed !== true

					case OrdersCompleteness.Completed:
						return order.completed === true

					default:
						return true
				}
			})

			switch (OrdersFilter.sort) {
				case OrdersSort.EndDate:
					return orders.sort((a, b) => a.endDate.getTime() - b.endDate.getTime())

				default:
					return orders.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
			}
		},
	},
})
</script>
