<template>
	<div class="h-100">
		<div id="column-chart"></div>
		<div id="line-chart"></div>
		<div id="timeline-chart"></div>
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import { Orders, OrdersFilter } from '@/store'

import { numberToRUB } from '@/scripts/filters'

import ApexCharts from 'apexcharts'
import ru from 'apexcharts/dist/locales/ru.json'

export default defineComponent({
	name: 'OrdersCharts',

	data() {
		return { columnChart: '', lineChart: '', timeLineChart: '' }
	},

	async mounted() {
		this.initColumnChart()
		this.initLineChart()
		this.initTimelineChart()
	},

	methods: {
		initColumnChart() {
			const options = {
				chart: {
					id: 'columnChart',
					locales: [ru],
					defaultLocale: 'ru',
					type: 'bar',
					// stacked: true,
					height: '24%',
					zoom: {
						enabled: false,
					},
					toolbar: { tools: { download: false } },
				},
				series: [],
				colors: ['#DC143C', '#00cc00'],
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '70%',
						endingShape: 'rounded',
					},
				},
				dataLabels: {
					enabledOnSeries: [1],
					formatter: (value) => {
						return numberToRUB(value)
					},
					offsetY: '-20px',
					style: {
						fontSize: '12px',
						colors: ['#333'],
					},
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent'],
				},
				xaxis: {
					type: 'datetime',
				},
				yaxis: {
					show: false,
				},
				legend: {
					show: false,
					position: 'top',
				},
				tooltip: {
					enabled: true,
					y: {
						formatter: (value) => {
							return numberToRUB(value)
						},
					},
				},
			}

			this.columnChart = new ApexCharts(document.querySelector('#column-chart'), options)
			this.columnChart.render()
		},

		updateColumnChart() {
			const data = Orders.lineChartData

			this.columnChart.updateSeries([
				// {
				// 	name: 'Постоянные затраты',
				// 	data: Object.keys(data.expensePerDay).map((key) => [new Date(key), data.expensePerDay[key]]),
				// },
				// {
				// 	name: 'Расход с украшений',
				// 	data: Object.keys(data.expense).map((key) => [new Date(key), data.expense[key]]),
				// },
				// {
				// 	name: 'Доход с украшений',
				// 	data: Object.keys(data.income).map((key) => [new Date(key), data.income[key]]),
				// },
				// {
				// 	name: 'Прибыль',
				// 	data: Object.keys(data.profit).map((key) => [new Date(key), data.profit[key]]),
				// },
				{
					name: 'Расход в месяц',
					data: Object.keys(data.expensePerMonth).map((key) => [new Date(key), data.expensePerMonth[key]]),
				},
				{
					name: 'Прибыль в месяц',
					data: Object.keys(data.profitPerMonth).map((key) => [new Date(key), data.profitPerMonth[key]]),
				},
			])

			console.log(data.profitPerMonth)
			// console.log(data.profit)
		},

		initLineChart() {
			const options = {
				chart: {
					id: 'lineChart',
					locales: [ru],
					defaultLocale: 'ru',
					type: 'line',
					width: '100%',
					height: '24%',
					zoom: {
						enabled: false,
					},
					toolbar: { tools: { download: false } },
				},
				stroke: {
					width: [3, 3, 3, 7],
					curve: 'straight',
				},
				series: [],
				colors: ['#A9A9A9', '#DC143C', '#FFD700', '#00cc00'],
				yaxis: {
					show: false,
				},
				xaxis: {
					type: 'datetime',
				},
				annotations: {
					yaxis: [
						{
							y: 0,
							strokeDashArray: 0,
							borderColor: '#00E396',
							label: {
								borderColor: '#00E396',
								style: {
									color: '#fff',
									background: '#00E396',
								},
								text: 'Ноль',
							},
						},
					],

					xaxis: [
						{
							x: Date.now(),
							strokeDashArray: 0,
							borderColor: '#775DD0',
							label: {
								borderColor: '#775DD0',
								style: {
									color: '#fff',
									background: '#775DD0',
								},
								text: 'Сегодня',
							},
						},
					],
				},

				legend: {
					show: false,
					position: 'top',
				},
				tooltip: {
					enabled: true,
					y: {
						formatter: (value) => {
							return numberToRUB(value)
						},
					},
				},
			}

			this.lineChart = new ApexCharts(document.querySelector('#line-chart'), options)
			this.lineChart.render()
		},

		updateLineChart() {
			const data = Orders.lineChartData

			this.lineChart.updateSeries([
				{
					name: 'Постоянные затраты',
					data: Object.keys(data.expensePerDay).map((key) => [new Date(key), data.expensePerDay[key]]),
				},
				{
					name: 'Расход с украшений',
					data: Object.keys(data.expense).map((key) => [new Date(key), data.expense[key]]),
				},
				{
					name: 'Доход с украшений',
					data: Object.keys(data.income).map((key) => [new Date(key), data.income[key]]),
				},
				{
					name: 'Прибыль',
					data: Object.keys(data.profit).map((key) => [new Date(key), data.profit[key]]),
				},
				// {
				// 	name: 'Прибыль по месяцам',
				// 	data: Object.keys(data.profitPerMonth).map((key) => [new Date(key), data.profitPerMonth[key]]),
				// },
			])

			console.log(data.profitPerMonth)
			// console.log(data.profit)
		},

		initTimelineChart() {
			const options = {
				chart: {
					id: 'timelineChart',
					locales: [ru],
					defaultLocale: 'ru',
					width: '100%',
					height: '49%',
					type: 'rangeBar',
					zoom: {
						enabled: false,
					},
					toolbar: { tools: { download: false } },
					events: {
						dataPointMouseEnter: (event) => {
							Orders.HOVER_ORDER(Orders.timelineChartData[event.srcElement.attributes.j.value].id)
						},
						dataPointMouseLeave: function() {
							Orders.HOVER_ORDER(0)
						},
					},
				},
				series: [],
				dataLabels: {
					enabled: true,
					formatter: function(val, opts) {
						const label = opts.w.globals.labels[opts.dataPointIndex]
						return label
					},
					style: {
						colors: ['#000000'],
					},
				},

				plotOptions: {
					bar: {
						horizontal: true,
					},
				},
				yaxis: {
					show: false,
				},
				xaxis: {
					type: 'datetime',
				},
				grid: {
					padding: {
						right: 12,
						left: -3,
					},
				},
			}

			this.timelineChart = new ApexCharts(document.querySelector('#timeline-chart'), options)
			this.timelineChart.render()
		},

		updateTimelineChart() {
			const data = Orders.timelineChartData

			this.timelineChart.updateOptions({
				xaxis: {
					min: data[0].y[0],
				},
			})
			this.timelineChart.updateSeries([
				{
					data,
				},
			])
		},
	},

	computed: {
		ordersLastUpdate() {
			return Orders.ordersLastUpdate
		},

		ordersFilter() {
			return OrdersFilter.sort + OrdersFilter.completeness
		},
	},

	watch: {
		ordersLastUpdate() {
			this.updateColumnChart()
			this.updateLineChart()
			this.updateTimelineChart()
		},

		ordersFilter() {
			this.updateTimelineChart()
		},
	},

	async unmounted() {
		await this.columnChart.destroy()
		await this.lineChart.destroy()
		await this.timelineChart.destroy()
	},
})
</script>
