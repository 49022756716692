
import { defineComponent } from 'vue'
import OrdersStats from '@/components/orders/orders-stats.component.vue'
import OrdersList from '@/components/orders/orders-list.component.vue'
import OrdersCharts from '@/components/orders/orders-charts.component.vue'
import { Orders } from '@/store'

export default defineComponent({
	name: 'Orders',
	components: {
		OrdersStats,
		OrdersList,
		OrdersCharts,
	},
	async mounted() {
		await Orders.getOrders()
		// console.log(this.ordersCalcId)
	},
	computed: {
		ordersJewelryId() {
			return Orders.ordersJewelryId
		},
	},
	watch: {
		ordersJewelryId() {
			// console.log(this.ordersJewelryId)
			// console.log('changed')
			Orders.getOrdersCostprice()
		},
	},
})
