
import _ from 'lodash'
import { defineComponent } from 'vue'
import { Order, OrdersCompleteness, OrderType, OrderIcon, JewelryWithCalc } from '@/models/orders.models'
import Datepicker from 'vue3-datepicker'
import { ru } from 'date-fns/locale'
import { Orders, OrdersFilter } from '@/store'
import { numberToRUB } from '@/scripts/filters'

export default defineComponent({
	name: 'OrderItem',
	components: {
		Datepicker,
	},
	props: { sourceOrder: { type: Object as () => Order, required: true } },
	data() {
		return {
			order: this.sourceOrder,
			ruLocale: ru,
		}
	},
	computed: {
		OrderType() {
			return OrderType
		},
		OrdersCompleteness() {
			return OrdersCompleteness
		},
		OrdersFilter() {
			return OrdersFilter
		},
		//Order prop GET SET
		title: {
			get(): string {
				return this.sourceOrder.title
			},
			set(title: string) {
				this.order.title = title
			},
		},
		orderType: {
			get(): string {
				return this.sourceOrder.orderType
			},
			set(orderType: string) {
				this.order.orderType = orderType
			},
		},
		client: {
			get(): string {
				return this.sourceOrder.client
			},
			set(client: string) {
				this.order.client = client
			},
		},
		jewelryId: {
			get(): number {
				return this.sourceOrder.jewelryId
			},
			set(jewelryId: number) {
				this.order.jewelryId = jewelryId
			},
		},
		costPrice: {
			get(): number | null {
				return this.sourceOrder.costPrice
			},
			set(costPrice: number) {
				this.order.costPrice = costPrice
			},
		},
		price: {
			get(): number | null {
				return this.sourceOrder.price
			},
			set(price: number) {
				this.order.price = price
			},
		},
		prepay: {
			get(): number | null {
				return this.sourceOrder.prepay
			},
			set(prepay: number) {
				this.order.prepay = prepay
			},
		},
		startDate: {
			get(): Date {
				return this.sourceOrder.startDate
			},
			set(startDate: Date) {
				this.order.startDate = startDate
			},
		},
		endDate: {
			get(): Date {
				return this.sourceOrder.endDate
			},
			set(endDate: Date) {
				this.order.endDate = endDate
			},
		},
		completed: {
			get(): boolean {
				return this.sourceOrder.completed
			},
			set(completed: boolean) {
				this.order.completed = completed
			},
		},
		//
		jewelryWithCalc() {
			return Orders.jewelryWithCalc
		},
		ordersCostprice() {
			return Orders.ordersCostprice
		},
		selectedJewelry(): JewelryWithCalc {
			return this.jewelryWithCalc.filter((jewelry) => jewelry.id === this.order.jewelryId)[0] || ({} as JewelryWithCalc)
		},
		placeholderTitle(): string {
			return this.selectedJewelry.title || 'Название заказа'
		},
		placeholderCostPrice(): number {
			return this.ordersCostprice[this.sourceOrder.jewelryId] ? this.ordersCostprice[this.sourceOrder.jewelryId] : 0
		},
		placeholderPrice(): number {
			return this.price ? this.price : this.selectedJewelry.price ? this.selectedJewelry.price : 0
		},
		hoveredOrderId() {
			return Orders.hoveredOrderId
		},
		orderIcon() {
			if (this.order.orderType === OrderType.NewOrder) return OrderIcon.NewOrder
			if (this.order.orderType === OrderType.OrderFromFinished) return OrderIcon.OrderFromFinished
			if (this.order.orderType === OrderType.Collection) return OrderIcon.Collection
			return ''
		},

		// orderClass() {},
	},

	methods: {
		numberToRUB(value: number) {
			return numberToRUB(value)
		},
		deleteOrder() {
			Orders.deleteOrder(this.order.id)
		},
	},

	watch: {
		order: {
			deep: true,
			handler: _.debounce((order: Order) => {
				Orders.saveOrder(order)
			}, 700),
		},
	},
})
