<template>
	<div class="row w-100 m-0">
		<div class="col-md-12 col-lg-6 col-xl-4 order-last order-lg-first">
			<OrdersStats />
			<OrdersList />
		</div>
		<div class="col-md-12 col-lg-6 col-xl-8 order-first order-lg-last p-0 charts sticky-lg-top">
			<OrdersCharts />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OrdersStats from '@/components/orders/orders-stats.component.vue'
import OrdersList from '@/components/orders/orders-list.component.vue'
import OrdersCharts from '@/components/orders/orders-charts.component.vue'
import { Orders } from '@/store'

export default defineComponent({
	name: 'Orders',
	components: {
		OrdersStats,
		OrdersList,
		OrdersCharts,
	},
	async mounted() {
		await Orders.getOrders()
		// console.log(this.ordersCalcId)
	},
	computed: {
		ordersJewelryId() {
			return Orders.ordersJewelryId
		},
	},
	watch: {
		ordersJewelryId() {
			// console.log(this.ordersJewelryId)
			// console.log('changed')
			Orders.getOrdersCostprice()
		},
	},
})
</script>

<style scoped>
.charts {
	top: var(--header-height);
	height: var(--page-height);
	z-index: inherit;
}
</style>
